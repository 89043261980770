import { get, post } from '../../apis';
import ProductRoutes from './Product.Routes';

const ProductServices = {
  getProducts: async (params) => {
    const data = await get(ProductRoutes.getProducts, params);
    return data;
  },
  getTopSellingProducts: async (params) => {
    const data = await get(ProductRoutes.getTopSellingProducts, params);
    return data;
  },
  getProductById: async (id) => {
    const data = await get(ProductRoutes.getProductById + id);
    return data;
  },
  getVat: async () => {
    const data = await get(ProductRoutes.getVat);
    return data;
  },
  placeOrder: async (obj) => {
    const data = await post(ProductRoutes.placeOrder, obj);
    return data;
  },
  getBrands: async (params) => {
    const data = await get(ProductRoutes.getBrands, params);
    return data;
  },
  getFlashSaleProducts: async (id, params) => {
    const data = await get(ProductRoutes.getFlashSaleProducts + id, params);
    return data;
  },
  addProductToList: async (obj) => {
    const data = await post(ProductRoutes.addProductToList, obj);
    return data;
  },
  getCategoryBySubCategoryId: async (subCatId) => {
    const data = await get(ProductRoutes.getCategoryBySubCategoryId + '/' + subCatId);
    return data;
  },
  getCartTaxAmount: async (obj) => {
    const data = await post(ProductRoutes.getCartTaxAmount, obj);
    return data;
  },
}

export default ProductServices