const ProductRoutes = {
  getProducts: 'buyer/getProducts',
  getTopSellingProducts: 'getTopSellingProducts',
  getProductById: 'buyer/getProduct/',
  getVat: 'buyer/getVAT/',
  placeOrder: 'buyer/orderPurchase',
  getBrands: 'getBrands',
  getFlashSaleProducts: 'getFlashSaleProducts/',
  addProductToList: 'dropshipper/addToList',
  getCategoryBySubCategoryId: 'getCategoryById',
  getCartTaxAmount: 'buyer/getCartTaxAmount',
};

export default ProductRoutes