export default function InputCom({
  label,
  type,
  name,
  placeholder,
  children,
  inputHandler,
  disabled,
  value,
  register,
  errors,
  minLength,
  maxLength,
  inputClasses,
  labelClasses = "text-qgray text-[13px] font-normal",
}) {
  return (
    <div className="input-com w-full h-full">
      {label && (
        <label
          className={`input-label capitalize block  mb-2 ${labelClasses || ""}`}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div className="flex items-center input-wrapper border border-qgray-border w-full h-full overflow-hidden relative ">
        <input
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          // onChange={inputHandler}
          className={`input-field placeholder:text-sm text-sm px-6 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none ${inputClasses || ""}`}
          type={type}
          id={name}
          minLength={minLength}
          maxLength={maxLength}
          {...register}
        />
        {children && children}
      </div>
      {errors &&
        <p className="text-[12px] text-qred">*{errors}</p>
      }
    </div>
  );
}
