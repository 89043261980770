import { useDispatch, useSelector } from "react-redux";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Debounce, DropShipperTypeId, emailRegex, mobileRegex } from "../../utils";
import ProductServices from "../../apis/product/ProductServices";
import Swal from "sweetalert2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../context/UseContext";
import { ErrorToaster } from "../Helpers/toaster/Toaster";
import { clearCart } from "../../redux/slices/userDataSlice";
import LoaderStyleOne from "../Helpers/Loaders/LoaderStyleOne";
import CommonServices from "../../apis/common/CommonServices";
import { Autocomplete, Box, Dialog, DialogTitle, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Fragment } from "react";

export default function CheakoutPage() {

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { cart, totalAmount } = useSelector(state => state.userDataReducer)
  const navigate = useNavigate()
  const { user } = useAuth()

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  // *For Select
  const [billCountries, setBillCountries] = useState([]);
  const [selectedBillCountry, setSelectedBillCountry] = useState(null);
  const [shipCountries, setShipCountries] = useState([]);
  const [selectedShipCountry, setSelectedShipCountry] = useState(null);
  const [billCities, setBillCities] = useState([]);
  const [selectedBillCity, setSelectedBillCity] = useState(null);
  const [shipCities, setShipCities] = useState([]);
  const [selectedShipCity, setSelectedShipCity] = useState(null);
  const [couriers, setCouriers] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('cod');

  const [openVoucherDialog, setOpenVoucherDialog] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [voucherError, setVoucherError] = useState('');

  const [is3plSelected, setIs3plSelected] = useState(false);
  const [plPdfFile, setPlPdfFile] = useState('');
  const [isAllowedByDaraz, setIsAllowedByDaraz] = useState(true);
  const [isPl, setIsPl] = useState(false);

  const [updatedCart, setUpdatedCart] = useState([]);

  const [totalTax, setTotalTax] = useState(0);
  const [darazServiceCharges, setDarazServiceCharges] = useState();

  let totalSellingAmount = 0

  // *For Get Bill Countries
  const getBillCountries = async (search) => {
    try {
      let params = {
        search: search
      }
      const { data } = await CommonServices.getCountries(params)
      setBillCountries(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCountries ~ error:", error)
    }
  }

  // *For Get Ship Countries
  const getShipCountries = async (search) => {
    try {
      let params = {
        search: search
      }
      const { data } = await CommonServices.getCountries(params)
      setShipCountries(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCountries ~ error:", error)
    }
  }

  // *For Get Bill Cities
  const getBillCities = async (page, country, search) => {
    try {
      const countryId = country ? country.id : selectedBillCountry?.id
      let params = {
        search: search,
        page: 1,
        limit: 100
      }
      const { data } = await CommonServices.getCities(countryId, params)
      setBillCities(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCities ~ error:", error)
    }
  }

  // *For Get Ship Cities
  const getShipCities = async (page, country, search) => {
    try {
      const countryId = country ? country.id : selectedShipCountry?.id
      let params = {
        search: search,
        page: 1,
        limit: 100
      }
      const { data } = await CommonServices.getCities(countryId, params)
      setShipCities(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCities ~ error:", error)
    }
  }

  // *For Get Couriers
  const getCouriers = async (search) => {
    try {
      let params = {
        search: search
      }
      const { data } = await CommonServices.getCouriers(params)
      setCouriers(data)
    } catch (error) {
      console.log("🚀 ~ file: index.jsx:118 ~ getCouriers ~ error:", error)
    }
  }

  // *For Place Order
  const placeOrder = async (data) => {
    setLoading(true)
    try {
      if (user?.token) {
        if (!selectedBillCountry && !is3plSelected) {
          ErrorToaster('Pease select bill country')
          return
        }
        if (!selectedBillCity && !is3plSelected) {
          ErrorToaster('Pease select bill city')
          return
        }
        if (!selectedShipCountry && !is3plSelected) {
          ErrorToaster('Pease select ship country')
          return
        }
        if (!selectedShipCity && !is3plSelected) {
          ErrorToaster('Pease select ship city')
          return
        }
        if (!selectedCourier && !is3plSelected) {
          ErrorToaster('Pease select courier service')
          return
        }
        if (is3plSelected) {
          // if (selectedShipCity?.name !== 'Karachi' || selectedBillCity?.name !== 'Karachi') {
          //   ErrorToaster('3pl is only allowed in karachi')
          //   return
          // }
          if (!isAllowedByDaraz) {
            ErrorToaster('Products in your carts is not allowed in daraz')
            return
          }
          if (!plPdfFile) {
            ErrorToaster('Pease upload 3PL pdf')
            return
          }
        }
        const products = []
        updatedCart?.forEach(element => {
          let prod = {
            id: element.id,
            qty: element.quantity,
            min_quantity: element.min_quantity,
            product_variation_id: element.size.productVariationId,
            // parent_variation_type_id: element.color.id,
            // child_variation_type_id: element.size.id,
          }
          if (element?.sellingPrice) {
            prod.ceiling_price = element?.sellingPrice
          }
          products.push(prod)
        });
        const formData = new FormData()
        formData.append("first_name", data.firstName)
        formData.append("last_name", data.lastName)
        formData.append("phone_number", data.phone)
        formData.append("email_address", data.email)
        formData.append("ship_to_address", data.shipAddress)
        formData.append("ship_country_id", selectedShipCountry?.id)
        formData.append("ship_city_id", selectedShipCity?.id)
        formData.append("ship_postal_code", data?.shipPostalCode)
        formData.append("bill_to_address", data?.billAddress)
        formData.append("bill_country_id", selectedBillCountry?.id)
        formData.append("bill_city_id", selectedBillCity?.id)
        formData.append("bill_postal_code", data.billPostalCode)
        formData.append("return_address", data.returnAddress)
        formData.append("products", JSON.stringify(products))
        formData.append("payment_method", paymentMethod)
        formData.append("courier_id", selectedCourier?.id)
        formData.append("is_3pl", is3plSelected ? 1 : 0)
        formData.append("3pl_pdf", plPdfFile)
        if (voucherCode) {
          formData.append("voucher_code", voucherCode)
        }
        const result = await ProductServices.placeOrder(formData)
        if (paymentMethod === 'cod') {
          dispatch(clearCart())
          Swal.fire({
            title: result?.message,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: `#234386`,
            confirmButtonText: 'OK'
          }).then(async (result) => {
            navigate('/')
            reset()
          })
        } else {
          const link = document.createElement('a');
          link.href = result?.data?.payment_url;
          document.body.appendChild(link);
          link.click();
          // Clean up and remove the link element
          link.parentNode.removeChild(link);
        }
      } else {
        ErrorToaster('First you need to login')
        navigate('/login')
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  }

  // *For Apply Voucher
  const applyVoucher = async (formData) => {
    setLoading2(true)
    try {
      const { data } = await CommonServices.applyVoucher(formData?.code)
      if (cart.filter(e => e?.seller_id !== data?.seller_id).length === 0) {
        if (totalAmount >= data?.amount_limit) {
          setVoucherCode(formData?.code)
          setDiscountAmount(data?.voucher_discount)
          setOpenVoucherDialog(false)
          reset2()
        } else {
          setVoucherError(`Min Order. PKR${data?.amount_limit}`)
        }
      } else {
        setVoucherError(`Voucher is only applicable when voucher and product seller is same`)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading2(false)
    }
  }

  // *For Remove Voucher
  const removeVoucher = () => {
    setDiscountAmount(0);
    setVoucherCode('')
  }

  // *For Upload Image
  const handleUploadImage = (e) => {
    if (e.target.value !== "") {
      setPlPdfFile(e.target.files[0])
    }
  };

  // *For Update Cart Data
  const updateCartData = () => {
    try {
      const updateCart = []
      cart.forEach(e => {
        let productPrice = e?.price
        if (e?.flash_sale_price > 0) {
          productPrice = e?.flash_sale_price
        } else if (e?.discounted_price > 0) {
          productPrice = e?.discounted_price
        } else if (e?.discount_percentage > 0) {
          productPrice = e?.price - (e?.discount_percentage / 100) * e?.price
        }
        let obj = {
          ...e,
          productTotal: productPrice * e.quantity,
        }
        if (user?.type == DropShipperTypeId) {
          obj.sellingPrice = productPrice * e.quantity
        }
        updateCart.push(obj)
      });
      setUpdatedCart(updateCart)
      // getCartTaxAmount(updateCart)
    } catch (error) {
      console.log('updateCartData => error:', error)
    }
  }

  // *For Add Selling Price in Cart Data
  const addSellingPrice = (index, sellingPrice) => {
    try {
      const cartCopy = updatedCart.map(item => ({ ...item }));
      cartCopy[index].sellingPrice = Number(sellingPrice)
      setUpdatedCart(cartCopy)
    } catch (error) {
      console.log('addSellingPrice => error:', error)
    }
  }

  // *For Get Total Tax on Order 
  const getCartTaxAmount = async (cartData) => {
    try {
      const products = []
      cartData?.forEach(element => {
        let prod = {
          id: element.id,
          qty: element.quantity,
          min_quantity: element.min_quantity,
          product_variation_id: element.size.productVariationId,
        }
        if (element?.sellingPrice) {
          prod.ceiling_price = element?.sellingPrice
        }
        products.push(prod)
      });
      const formData = new FormData()
      formData.append("products", JSON.stringify(products))
      const { data } = await ProductServices.getCartTaxAmount(formData)
      setTotalTax(data?.total_tax)
    } catch (error) {
      console.log('getCartTaxAmount => error:', error)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
    getBillCountries()
    getShipCountries()
    getCouriers()
    updateCartData()
    setValue('firstName', user?.name)
    setValue('lastName', user?.name)
    setValue('email', user?.email)
    setValue('phone', user?.contact)
  }, []);

  useEffect(() => {
    const checkIsAllowedByDaraz = cart.every(e => e.is_allowed_by_daraz == 1)
    setIsAllowedByDaraz(checkIsAllowedByDaraz)
    const totalServiceCharges = cart.reduce((sum, prod) => sum + parseInt(prod?.service_charges || 0), 0)
    setDarazServiceCharges(totalServiceCharges)
    const checkIsPl = cart.every(e => e.is_pl == 1)
    setIsPl(checkIsPl)
    if (checkIsPl) {
      setPaymentMethod('paypro')
    }
  }, []);

  useEffect(() => {
    const paymentStatus = searchParams.get('status')
    if (paymentStatus == 1) {
      dispatch(clearCart())
      Swal.fire({
        title: "Order purchased successfully!",
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: `#234386`,
        confirmButtonText: 'OK'
      }).then(async (result) => {
        navigate('/')
        reset()
      })
    } else if (paymentStatus == 0) {
      Swal.fire({
        title: searchParams.get('message'),
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: `#234386`,
        confirmButtonText: 'OK'
      }).then(async (result) => {
        navigate('/')
        reset()
      })
    }
  }, [searchParams]);

  return (
    <Layout childrenClasses="pt-0 pb-0">

      {/* ======== Voucher Dialog Box ======= */}
      <Dialog
        maxWidth='xs'
        open={openVoucherDialog}
        sx={{ '& .MuiDialog-paper': { width: '80%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
      >
        <IconButton color="primary" onClick={() => setOpenVoucherDialog(false)} sx={{ position: 'absolute', right: 13, top: 13 }}>
          <Close />
        </IconButton>
        <DialogTitle sx={{ textAlign: "center", fontSize: '18px', fontWeight: 700 }}>Apply Voucher</DialogTitle>
        <Box component="form" onSubmit={handleSubmit2(applyVoucher)}>
          <div className="w-full mb-2">
            <InputCom
              label="Apply Voucher"
              placeholder="Enter voucher code"
              inputClasses="w-full h-[50px]"
              errors={errors2?.code?.message}
              register={register2("code", {
                required: 'Please enter your voucher code.',
              })}
            />
          </div>
          <p style={{ color: '#EF262C' }} className="cursor-pointer mb-3 text-[13px] font-600 text-qblack leading-[24px] line-clamp-2">
            {voucherError}
          </p>
          <button onClick={handleSubmit2(applyVoucher)} disabled={loading2} className="w-full h-[50px] black-btn flex justify-center items-center">
            <span className="text-sm font-semibold">
              Apply
            </span>
            {loading2 && (
              <span className="w-5 " style={{ transform: "scale(0.3)" }}>
                <LoaderStyleOne />
              </span>
            )}
          </button>
        </Box>
      </Dialog>

      <div className="checkout-page-wrapper w-full bg-white pb-[60px]">
        <div className="w-full mb-5">
          <PageTitle
            title="Checkout"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "checkout", path: "/checkout" },
            ]}
          />
        </div>
        <div className="checkout-main-content w-full">
          <div className="container-x mx-auto">
            {/* <div className="w-full sm:mb-10 mb-5">
              <div className="sm:flex sm:space-x-[18px] s">
                <div className="sm:w-1/2 w-full mb-5 h-[70px]">
                  <a href="#">
                    <div className="w-full h-full bg-[#F6F6F6] text-qblack flex justify-center items-center">
                      <span className="text-[15px] font-medium">
                        Log into your Account
                      </span>
                    </div>
                  </a>
                </div>
                <div className="flex-1 h-[70px]">
                  <a href="#">
                    <div className="w-full h-full bg-[#F6F6F6] text-qblack flex justify-center items-center">
                      <span className="text-[15px] font-medium">
                        Enter Coupon Code
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </div> */}
            <form onSubmit={handleSubmit(placeOrder)}>
              <div className="w-full lg:flex lg:space-x-[30px]">
                <div className="lg:w-1/2 w-full">
                  <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                    Personal Details
                  </h1>
                  <div className="form-area">
                    <div className="sm:flex sm:space-x-5 items-center mb-6">
                      <div className="sm:w-1/2  mb-5 sm:mb-0">
                        <InputCom
                          label="First Name*"
                          placeholder="First Name"
                          inputClasses="w-full h-[50px]"
                          errors={errors?.firstName?.message}
                          register={register("firstName", {
                            required: 'Please enter a first name.',
                          })}
                        />
                      </div>
                      <div className="flex-1">
                        <InputCom
                          label="Last Name*"
                          placeholder="Last Name"
                          inputClasses="w-full h-[50px]"
                          errors={errors?.lastName?.message}
                          register={register("lastName", {
                            required: 'Please enter a last name.',
                          })}
                        />
                      </div>
                    </div>
                    <div className="flex space-x-5 items-center mb-6">
                      <div className="w-1/2">
                        <InputCom
                          label="Email Address*"
                          placeholder="emial@gmail.com"
                          inputClasses="w-full h-[50px]"
                          errors={errors?.email?.message}
                          register={register("email", {
                            required: 'Please enter an email address.',
                            pattern: {
                              value: emailRegex,
                              message: 'Please enter a valid email.',
                            }
                          })}
                        />
                      </div>
                      <div className="flex-1">
                        <InputCom
                          label="Phone Number*"
                          type='tel'
                          placeholder="+92312 *********"
                          inputClasses="w-full h-[50px]"
                          errors={errors?.phone?.message}
                          register={register("phone", {
                            required: 'Please enter a phone number.',
                            pattern: {
                              value: mobileRegex,
                              message: 'Please enter a mobile number.',
                            }
                          })}
                        />
                      </div>
                    </div>
                    {!is3plSelected &&
                      <Fragment>
                        <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                          Billing Details
                        </h1>
                        <div className="mb-6">
                          <div className="w-full">
                            <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                              Country*
                            </span>
                            <div className="w-full">
                              <Autocomplete
                                disabled={is3plSelected}
                                disablePortal
                                autoHighlight
                                options={billCountries}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => { setSelectedBillCountry(newValue); getBillCities(1, newValue) }}
                                onInputChange={(event, newInputValue) => Debounce(() => getBillCountries(newInputValue))}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className=" mb-6">
                          <div className="w-full">
                            <InputCom
                              disabled={is3plSelected}
                              label="Address*"
                              placeholder="your address here"
                              inputClasses="w-full h-[50px]"
                              errors={errors?.billAddress?.message}
                              register={register("billAddress", {
                                required: is3plSelected ? false : 'Please enter your address.',
                              })}
                            />
                          </div>
                        </div>
                        <div className="flex space-x-5 items-center mb-6">
                          <div className="w-1/2">
                            <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                              City*
                            </span>
                            <div className="w-full">
                              <Autocomplete
                                disablePortal
                                autoHighlight
                                disabled={is3plSelected ? true : selectedBillCountry ? false : true}
                                options={billCities}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => setSelectedBillCity(newValue)}
                                onInputChange={(event, newInputValue) => Debounce(() => getBillCities(1, '', newInputValue))}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                  />
                                }
                              />
                            </div>
                          </div>
                          <div className="flex-1">
                            <InputCom
                              disabled={is3plSelected}
                              label="Postcode / ZIP*"
                              placeholder=""
                              inputClasses="w-full h-[50px]"
                              errors={errors?.billPostalCode?.message}
                              register={register("billPostalCode", {
                                required: is3plSelected ? false : 'Please enter postal code.',
                              })}
                            />
                          </div>
                        </div>
                        <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                          Shipping Details
                        </h1>
                        <div className="mb-6">
                          <div className="w-full">
                            <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                              Country*
                            </span>
                            <div className="w-full">
                              <Autocomplete
                                disabled={is3plSelected}
                                disablePortal
                                autoHighlight
                                options={shipCountries}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => { setSelectedShipCountry(newValue); getShipCities(1, newValue) }}
                                onInputChange={(event, newInputValue) => Debounce(() => getShipCountries(newInputValue))}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className=" mb-6">
                          <div className="w-full">
                            <InputCom
                              disabled={is3plSelected}
                              label="Address*"
                              placeholder="your address here"
                              inputClasses="w-full h-[50px]"
                              errors={errors?.shipAddress?.message}
                              register={register("shipAddress", {
                                required: is3plSelected ? false : 'Please enter your address.',
                              })}
                            />
                          </div>
                        </div>
                        <div className=" mb-6">
                          <div className="w-full">
                            <InputCom
                              disabled={is3plSelected}
                              label="Return Address*"
                              placeholder="your return address here"
                              inputClasses="w-full h-[50px]"
                              errors={errors?.returnAddress?.message}
                              register={register("returnAddress", {
                                required: is3plSelected ? false : 'Please enter your return address.',
                              })}
                            />
                          </div>
                        </div>
                        <div className="flex space-x-5 items-center mb-6">
                          <div className="w-1/2">
                            <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                              City*
                            </span>
                            <div className="w-full">
                              <Autocomplete
                                disablePortal
                                autoHighlight
                                disabled={is3plSelected ? true : selectedShipCountry ? false : true}
                                options={shipCities}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => setSelectedShipCity(newValue)}
                                onInputChange={(event, newInputValue) => Debounce(() => getShipCities(1, '', newInputValue))}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                  />
                                }
                              />
                            </div>
                          </div>
                          <div className="flex-1">
                            <InputCom
                              disabled={is3plSelected}
                              label="Postcode / ZIP*"
                              placeholder=""
                              inputClasses="w-full h-[50px]"
                              errors={errors?.shipPostalCode?.message}
                              register={register("shipPostalCode", {
                                required: is3plSelected ? false : 'Please enter postal code.',
                              })}
                            />
                          </div>
                        </div>
                      </Fragment>
                    }
                    {/* <div className="flex space-x-2 items-center mb-10">
                    <div>
                      <input type="checkbox" name="" id="create" />
                    </div>
                    <label
                      htmlFor="create"
                      className="text-qblack text-[15px] select-none"
                    >
                      Create an account?
                    </label>
                  </div> */}
                    {user?.type == DropShipperTypeId &&
                      <div>
                        <h1 className="text-2xl text-qblack font-medium mb-3">
                          Is 3PL
                        </h1>
                        <div className="flex space-x-2 items-center mb-10">
                          <div>
                            <input disabled={!isAllowedByDaraz} type="checkbox" name="" id="address" checked={is3plSelected} onClick={() => { setIs3plSelected(!is3plSelected); setPaymentMethod('paypro') }} />
                          </div>
                          <label
                            htmlFor="address"
                            className="text-qblack text-[15px] select-none"
                          >
                            3PL option
                          </label>
                          {!isAllowedByDaraz &&
                            <p className="text-[12px] text-qred ml-3">(Products in your carts is not allowed in daraz)</p>
                          }
                        </div>
                      </div>
                    }
                    {is3plSelected &&
                      <div className="update-cover w-full">
                        <h1 className="text-lg tracking-wide font-bold text-qblack flex items-center mb-2">
                          Attach a PDF file.
                        </h1>
                        <InputCom
                          type={'file'}
                          inputClasses="w-full h-[50px]"
                          errors={errors?.plpdf?.message}
                          register={register("plpdf", {
                            required: is3plSelected ? 'Please upload a pdf file.' : false,
                            onChange: e => handleUploadImage(e)
                          })}
                        />
                      </div>
                    }
                  </div>
                </div>
                <div className="flex-1">
                  <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                    Order Summary
                  </h1>

                  <div className="w-full px-10 py-[30px] border border-[#EDEDED]">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <tbody>
                        {/* table heading */}
                        <tr className="text-base text-qgray whitespace-nowrap px-2 border-b default-border-bottom ">
                          <td className="py-3 block whitespace-nowrap">Products</td>
                          <td className="py-3 whitespace-nowrap text-center">Total</td>
                          {user?.type == DropShipperTypeId &&
                            <td className="py-3 whitespace-nowrap text-center">Selling Price</td>
                          }
                        </tr>
                        {/* table heading end */}
                        {updatedCart?.map((item, index) => {
                          totalSellingAmount += item?.sellingPrice
                          return (
                            <tr key={index} className="bg-white border-b">
                              <td className="py-3 px-1">
                                <span className="text-base text-[14px] text-qgray whitespace-nowrap">
                                  {item.name}
                                  <sup className="text-[12px] text-qgray ml-2 mt-2">
                                    x{item.quantity}
                                  </sup>
                                </span>
                              </td>
                              <td className="text-center py-3 px-1">
                                <span className="text-base text-qgray whitespace-nowrap">
                                  <span className="text-[14px] text-qgray font-medium">
                                    PKR{item?.productTotal}
                                  </span>
                                </span>
                              </td>
                              {user?.type == DropShipperTypeId &&
                                <td className="text-center py-3 px-1 w-[70px]">
                                  {/* <InputCom
                                      label=""
                                      placeholder=""
                                      type="number"
                                      inputClasses="h-[30px] px-[8px] text-qblack"
                                      errors={errors?.sellingPrice?.message}
                                      register={register("sellingPrice", {
                                        required: 'Please enter your selling price.',
                                        onChange: e => addSellingPrice(index, e.target?.value)
                                      })}
                                    /> */}
                                  <div className="input-com w-full h-full">
                                    <div className="flex items-center input-wrapper border border-qgray-border w-full h-full overflow-hidden relative ">
                                      <input
                                        className={`h-[30px] px-[8px] text-qblack input-field placeholder:text-sm text-sm text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none`}
                                        type={'number'}
                                        defaultValue={item?.sellingPrice}
                                        {...register(`sellingPrice-${item?.id}`, {
                                          required: 'Please enter your selling price.',
                                          onChange: e => addSellingPrice(index, e.target?.value)
                                        })}
                                      />
                                    </div>
                                    {errors?.sellingPrice?.message &&
                                      <p className="text-[11px] text-qred">*{errors?.sellingPrice?.message}</p>
                                    }
                                  </div>
                                </td>
                              }
                            </tr>
                          )
                        })}
                        <tr className="bg-white border-b">
                          <td className="py-3 px-1">
                            <span className="text-[14px] text-base text-qblack whitespace-nowrap">
                              SUBTOTAL
                            </span>
                          </td>
                          <td className="text-center py-3 px-1">
                            <span className="text-[14px] text-base text-qblack whitespace-nowrap">
                              PKR{parseInt(totalAmount).toFixed(2)}
                            </span>
                          </td>
                          {user?.type == DropShipperTypeId &&
                            <td className="text-center py-3 px-1">
                              <span className="text-[14px] text-base text-qblack whitespace-nowrap">
                                PKR{parseInt(totalSellingAmount).toFixed() || parseInt(totalAmount).toFixed(2)}
                              </span>
                            </td>
                          }
                        </tr>
                      </tbody>
                    </table>

                    {/* <div className="w-full h-[1px] bg-[#EDEDED]"></div> */}

                    {/* <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <p className="text-[15px] font-medium text-qblack uppercase">
                          SUBTOTAL
                        </p>
                        <p className="text-[16px] font-medium text-qblack uppercase">
                          {user?.type == DropShipperTypeId ? (
                            `PKR${totalSellingAmount}`
                          ) : (
                            `PKR${totalAmount}`
                          )}
                        </p>
                      </div>
                    </div> */}
                    {discountAmount > 0 &&
                      <div className="mt-[30px]">
                        <div className=" flex justify-between mb-5">
                          <p className="text-[14px] font-medium text-qblack uppercase">
                            Discount
                          </p>
                          <p className="text-[14px] font-medium text-qblack uppercase">
                            PKR{discountAmount}
                          </p>
                        </div>
                      </div>
                    }
                    {/* <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <p className="text-[14px] font-medium text-qblack uppercase">
                          Tax
                        </p>
                        <p className="text-[15px] font-medium text-qblack uppercase">
                          PKR{totalTax}
                        </p>
                      </div>
                    </div> */}

                    <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <p className="text-[14px] font-medium text-qblack uppercase">
                          Daraz Service Charges
                        </p>
                        <p className="text-[15px] font-medium text-qblack uppercase">
                          PKR{darazServiceCharges}
                        </p>
                      </div>
                    </div>

                    {!is3plSelected &&
                      <div className="w-full mt-[30px]">
                        <div className="sub-total mb-6">
                          <div className="w-full">
                            <span className="text-sm font-normal text-qgray mb-[5px] inline-block" style={{ fontSize: '13px' }}>
                              Courier Service
                            </span>
                            <div className="w-full">
                              <Autocomplete
                                disabled={is3plSelected}
                                disablePortal
                                autoHighlight
                                options={couriers}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, newValue) => setSelectedCourier(newValue)}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    variant="standard"
                                  />
                                }
                              />
                            </div>
                          </div>
                          <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                        </div>
                      </div>
                    }

                    <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <div className="w-full">
                        </div>
                        <button type="button" onClick={() => discountAmount > 0 ? removeVoucher() : setOpenVoucherDialog(true)} className="w-[200px] h-[50px] black-btn flex justify-center items-center">
                          <span className="text-sm font-semibold">
                            {discountAmount > 0 ? 'Remove Voucher' : 'Apply Voucher'}
                          </span>
                        </button>
                      </div>

                    </div>

                    <div className="mt-[30px]">
                      <div className=" flex justify-between mb-5">
                        <p className="text-2xl font-medium text-qblack">Total</p>
                        <p className="text-2xl font-medium text-qred">PKR {parseInt(totalAmount - discountAmount + darazServiceCharges).toFixed(2)}</p>
                      </div>
                    </div>
                    <div className="shipping mt-[30px]">
                      <ul className="flex flex-col space-y-1">
                        {/* <li className=" mb-5">
                          <div className="flex space-x-2.5 items-center mb-4">
                            <div className="input-radio">
                              <input
                                type="radio"
                                name="price"
                                className="accent-pink-500"
                                id="transfer"
                              />
                            </div>
                            <label
                              htmlFor="transfer"
                              className="text-[18px] text-normal text-qblack"
                            >
                              Direct Bank Transfer
                            </label>
                          </div>
                          <p className="text-qgraytwo text-[15px] ml-6">
                            Make your payment directly into our bank account.
                            Please use your Order ID as the payment reference.
                          </p>
                        </li> */}
                        <li>
                          <div className="flex space-x-2.5 items-center mb-5">
                            <div className="input-radio">
                              <input
                                disabled={is3plSelected || isPl}
                                type="radio"
                                name="price"
                                className="qblue"
                                id="cod"
                                checked={paymentMethod === 'cod'}
                                onClick={() => setPaymentMethod('cod')}
                              />
                            </div>
                            <label
                              htmlFor="cod"
                              className="text-[18px] text-normal text-qblack"
                            >
                              Cash on Delivery
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="flex space-x-2.5 items-center mb-5">
                            <div className="input-radio">
                              <input
                                type="radio"
                                name="price"
                                className="qblue"
                                id="paypro"
                                checked={paymentMethod === 'paypro'}
                                onClick={() => setPaymentMethod('paypro')}
                              />
                            </div>
                            <label
                              htmlFor="paypro"
                              className="text-[18px] text-normal text-qblack"
                            >
                              Paypro
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <button type="submit" disabled={loading} className="w-full h-[50px] black-btn flex justify-center items-center mb-2">
                      <span className="text-sm font-semibold">
                        Place Order Now
                      </span>
                      {loading && (
                        <span className="w-5 " style={{ transform: "scale(0.3)" }}>
                          <LoaderStyleOne />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
